.pdfp_wrapper {
  // margin: 0 auto;
  .pdf {
    position: relative;
  }
  .iframe_wrapper {
    width: 100%;
    &:fullscreen {
      iframe {
        height: 100vh !important;
      }
    }
    iframe {
      width: 100%;
    }
    .close {
      position: absolute;
      top: 35px;
      right: 12px;
      padding: 0px 7px;
      background: #fff;
      color: #222;
      font-size: 36px;
      cursor: pointer;
      font-family: sans-serif;
      border: 1px solid #ddd;
      display: none;
      z-index: 9999;
      line-height: 100%;
      border-radius: 3px;
    }
    &:fullscreen .close {
      display: block;
    }
  }
}
.pdfp-adobe-viewer {
  border: 1px solid #ddd;
  outline: none;
  border-radius: 3px;
  cursor: pointer;
  text-decoration: none;
}
.pdfp_download {
  margin-right: 15px;
}
.cta_wrapper {
  margin-bottom: 10px;
}
.pdfp_wrapper p {
  margin: 10px 0;
  text-align: center;
}
.popout-disabled {
  right: 12px;
  top: 12px;
  position: absolute;
  width: 50px;
  height: 50px;
}

@media screen and (max-width: 768px) {
  .pdfp_wrapper iframe {
    height: calc(100vw + 120px);
  }
}
iframe {
  max-width: 100%;
}
.ViewSDK_hideOverflow[data-align="center"] {
  margin-left: auto;
  margin-right: auto;
}
.ViewSDK_hideOverflow[data-align="left"] {
  margin-right: auto;
}
.ViewSDK_hideOverflow[data-align="right"] {
  margin-left: auto;
}
@media screen and (max-width: 768px) {
  .pdfp_wrapper iframe {
    height: calc(100vw + 120px) !important;
  }
}
@media screen and (max-width: 576px) {
  .cta_wrapper .pdfp_download {
    margin-bottom: 10px;
    margin-right: 0;
    button {
      margin-right: 0 !important;
    }
  }
  .cta_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
